
.contact_map{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 2;
}
.contact {
  width: 100%;
  height: 100vh;
  position: relative;
  &_block{
    padding-top: 160px;
    padding-left: 60px;
    width: 40%;
    height: 100%;
    background: rgba(183, 183, 183, 0.43);
    z-index: 4;
    backdrop-filter: blur(4px);
    position:absolute;
    & h1{
      margin-bottom: 40px;
    }
    &_box{
      margin: 20px 0;
      & a {
        font-size: 24px;
        text-decoration: none;
        color: black;
      }
      & h6 {
        & a{
          font-size: 24px;
          color :red ;
          text-decoration: none;
        }
      }
      & p {
        font-weight: 200;
      }
    }
    &_btn {
      margin-top: 40px;
      border: 1px solid #ffffff;
      border-radius: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background: #000;
      z-index: 2;
      & a{
        z-index: 2;
        background: #2b91e7;
        text-decoration: none;
        color: white;
        padding:13px 60px;
        margin: 3px 3px;
        border-radius: 10px;
      }
      &:hover {
        color: red;
      }
      &:before{
        width: 30px;
        position: absolute;
        content: '';
        height: 330%;
        background: linear-gradient(#00ff2a, #00d0ff);
        animation:btn 2s infinite;
        @keyframes btn {
      0%{
        transform: rotate(360deg);
      }
        }
        &:after{
          position: absolute;
          content: '';
          background: transparent;
          inset: 3px;
          z-index: 2;
        }
      }
    }
    &_btn2 {
      margin-top: 40px;
      border: 1px solid #ffffff;
      border-radius: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background: #000;
      z-index: 2;
      & a{
        z-index: 2;
        background: #2b91e7;
        text-decoration: none;
        color: white;
        padding:13px 60px;
        margin: 3px 3px;
        border-radius: 10px;
      }
      &:hover {
        color: red;
      }
      &:before{
        width: 30px;
        position: absolute;
        content: '';
        height: 330%;
        background: linear-gradient(#00ff2a, #00d0ff);
        animation:btn 2s infinite;
        @keyframes btn {
      0%{
        transform: rotate(360deg);
      }
        }
        &:after{
          position: absolute;
          content: '';
          background: transparent;
          inset: 3px;
          z-index: 2;
        }
      }
    }
  }
}

.btn_con{
  display: none;
}


@media (max-width: 768px){
  .contact_block {
padding-left: 25px;
  }
}

@media (max-width: 576px){
  .contact_block {
    width: 58%;
  }
}
@media (max-width: 425px){
  .contact_block {
      width: 77%;
  }
  .btn_con{
    display: flex;
    justify-content: center;
    padding-top: 500px;
  }
}
@media (max-width: 414px){
  .contact_block {
    //display: none;
  }
  .btn_con{
    display: flex;
    justify-content: center;
    padding-top: 500px;
  }
}