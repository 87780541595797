

#indexHero{
  //background: url("../../../images/photo slide.png")no-repeat center/cover;
  background: url('../../../images/Снимок экрана 2022-04-12 в 16.09.00.png')no-repeat center/cover;
  height: 90vh;
}
//.block{
//  width: 300px;
//  height: 400px;
//  background: rgba(0, 0, 0, 0.24);
//  -webkit-text-stroke: 1px  white;
//  color: transparent;
//  padding: 140px 190px;
//}
//.App{
//  text-align: center;
//}
ul{
  list-style: none;
}
//li{
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  gap : 0.5rem;
//}
//button {
//  background: transparent;
//  border: none;
//  cursor: pointer;
//}
//input:checked + span {
//  text-decoration: line-through;
//}
.hero {
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  & h1{
    font-size: 38px;
    color: #fff;
    text-align: right;
    margin-top: 30%;
    font-weight: bold;
    & p {
      font-size: 12px;
      font-weight: 250;
    }
  }
}





@media (max-width: 576px) {
  .hero h1{
    font-size: 28px;
  }

}
@media (max-width: 320px) {
  .hero h1{
    font-size: 24px;
  }

}


