

.history-titles{
  &-box{
    display: flex;
    align-items: ceil(5);
    & img {
      height: 320px;
      object-fit: contain;
    }
    & h1 {
      font-size: 24px;
      font-weight: 500;
    }
  }
  &-block {
    display: flex;
    & p {
      margin: 0 20px;
    }
  }
}