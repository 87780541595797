
@import "~bootstrap/scss/bootstrap";
@import "sass/Header-Footer/Header";
@import "sass/Header-Footer/footer";
@import "./sass/IndexLanding/indexHero";
@import "./sass/IndexLanding/indexHeroAbout";
@import "./sass/Documents/doc-nor";
@import "./sass/Documents/doc-pdf";
@import "./sass/contact";
@import "./sass/history";
@import "./sass/news";
@import "./sass/teach";
@import "./sass/moni-img";



.mtb_block{
  position: relative;
  padding: 10px;
  width: 95%;
  display: flex;flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: .4s;
  &:hover{
    border: 1px solid #2b91e7;
  }
  &:hover  img {
    transform: scale(1.05);
  }
  & img{
    width: 30%;
    transition: .4s;
  }
  & h1 {
    text-transform: capitalize;
    margin: 10px 0;
    text-align: center;
    font-weight: 400;
    font-size: 17px;
  }
}


.teach{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &_block{
    margin: 13px 0;
    width: 80%;
    height: 500px;
    position: relative;
    overflow: hidden;
    & img{
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
}
