

.index-about {
  &-title{
    display: flex;
    justify-content: center;
    align-items: center;
    & h1{
      font-size: 64px;
      color: #5fafce;
      //margin-bottom: 73px;
      font-weight: 700;
      margin-top: 60px;
    }
  }
  &-desc {
    color: #000;
    font-size: 18px;
    padding: 50px 0;
    margin-bottom: 25px;
    position: relative;
  }
  &-block{
    display: flex;
    justify-content: end;
    & button  {
      color: #fff;
      padding: 7px 24px;
      border-radius: 23px;
      border: none;
      background: #25809d;
      transition: 0.4s;
      &:hover{
        background: #5fafce;
      }
    }
  }
  &-box{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 30px;
    &-img{
      width: 75%;
      height: 260px;
      margin-left: 100px;
      margin-top: -50px;
      object-fit: cover;
    }
    &-imgBk{
      position: absolute;
      z-index: -1;
      margin-top: 30px;
      width: 75%;
      height: 260px;
    }
  }
}


#dr{
  width: 100%;
  position: relative;
  height: 70vh;
  margin-bottom: 10px;
  background: url("https://aktilek.kg/static/media/bg2.cae5c0bb.png")no-repeat center/cover;
}
.index-dr {
  &-desc {
    margin-top: 10%;
    color: white;
    font-size: 24px;
    padding: 50px 0;
    margin-bottom: 25px;
    font-weight: 700;
    position: relative;
    width: 95%;
  }
  &-block{
    display: flex;
    justify-content: start;
    & p  {
      color: #fff;
      border: none;
      font-size: 18px;
      line-height: 1.5;
      background: #25809d;
    }
  }
  &-box{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 30px;
    &-img{
      width: 45%;
      border-radius: 5px  ;
      height: 400px;
      margin-left: -100px;
      margin-top: 20px;
      object-fit: cover;
      z-index: 3;
    }
    &-imgBk{
      position: absolute;
      margin-top: 110px;
      width: 45%;
      height: 400px;
    }
  }
}
//https://aktilek.kg/static/media/bg3.006cba65.png
#about  {
  background: url("https://aktilek.kg/static/media/bg4.45b1ea43.png") no-repeat center/cover;
  min-height: 80vh;
  background-attachment: fixed;
}
@media (max-width: 768px) {
  .index-dr-box-img {
    width: 82%;
  }
}
@media (max-width: 576px) {
  .index-dr-box-img {
    display: none;
  }
  .index-about-title h1 {
    font-size: 32px;
  }
}