

.news_block{
  width: 100%;
  height: 320px;
  position: relative;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.news_box{

  & h1 {
    font-size: 18px;
    font-weight: 400;

  }
}