a, a:hover{
  text-decoration: none;
}

#header{
  z-index: 99;
  position: sticky;
  top: 0;
  background: white;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar{
  position: relative;
  display: flex;
  align-items: center;
  &__a{
    font-size: 18px;
    color: #25809d;
    font-weight: 600;
    margin: 0 20px;
    position: relative;
    text-transform: uppercase;
    &:before{
      position: absolute;
      content : "";
      width: 100%;
      height: 2px;
      background:#5fafce ;
      bottom: -2%;
      transition: .4s;
      transform: scale(0);
    }
    &:hover:before{
      transform: scale(1);
    }
    &:hover .header_block{
      transform: scaleX(1);
    }
    &:hover{
      color: #5fafce;
    }
    &:hover img{
      transform: rotate(90deg);
    }
  }
}
.header_img{
  transition: .4s;
  width: 20px;
  height: 17px;
  margin-top: -1%;
}
.header_block{
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #25809d;
  border-radius: 5px;
  padding: 3px;
  background: ghostwhite;
  width: 300px;
  transition: .3s;
  left: 0;
  transform: scaleX(0);
  & a {
    font-size: 15px;
    font-weight: 250;
  }
}
.header_logo{
  width: 80px;
  margin-left: 50px;
  position: relative;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover:before {
    opacity: 1;
  }
  &:before {
    transition: .3s;
    font-size: 12px;
    color: black;
    width: 120px;
    position: absolute;
    content : "Главная ст...";
    right: 20px;
    top: 0;
    opacity: 0;
  }
}

.headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.burger1 {
  position: absolute;
  right: 30px;
  display: none;
  top: 40px;
}
.bm-burger-button {
  width: 50px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;


  &:before{
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    background: black;
  }
  &:after{
    bottom: 0;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-bottom: 4px solid black;
    border-top: 4px solid black;
  }
}
.bm-cross-button{
  width: 50px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before{
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    background: white;
    transform: rotate(-45deg);
    margin-top: 70%;
  }
  &:after{
    bottom: 0;
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    background: white;
    transform: rotate(45deg);
  }
}
.bm-overlay{
  left: 0;
  top: 0;
  opacity: 0.8 !important;
  background: #000 !important;
  filter: grayscale(1);
}
.bm-menu{
  background: #2b91e7 !important;
  width:150%;
  padding: 30px;
  margin-left:-40%;
  margin-top: -25%;
}
.burger{
  position: relative;
  display: flex;
  flex-direction: column;
  &__a{
    font-size: 18px;
    color: white;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
    &:before{
      position: absolute;
      content : "";
      width: 100%;
      height: 2px;
      bottom: -2%;
      transition: .4s;
      transform: scale(0);
    }
    &:hover:before{
      transform: scale(1);
    }
    &:hover .footer_block{
      transform: scaleX(1);
    }
    &:hover{
      color: #5fafce;
    }
    &:hover img{
      transform: rotate(90deg);
    }
  }
}

@media (max-width: 1024px) {
  .navbar {
    &__a {
      margin:  0 10px ;font-size: 14px;
    }
  }
  .header_block a{
    font-size: 13px;
  }

}

@media (max-width: 768px) {
  .navbar{
    display: none;
  }
  .burger1{
    display: block;
  }
}
@media (max-width: 375px) {
  .bm-menu{
    margin-left: -27%;
  }
}
