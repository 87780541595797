
.doc-block{
  padding: 30px 20px;
  background: rgb(240,240,240);
}
.doc-pdf{
  display: flex;
  justify-content: center;
  & h1{
    font-size: 32px;
    font-weight: 500;
  }
  &-box{
    transition: .3s;
    &:hover {
      transform: scale(1.04);
      box-shadow:4px 4px 10px black;
    }
    &:hover .p {
      opacity: 1;
      margin-top: 0;
    }
    position: relative;
    height: 450px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid black;
    margin:  13px 0;
    &-img{
      width: 100%;
      height: 70%;
      position: relative;
      overflow: hidden;
      & img {
        transition: .3s;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-title{
      position: relative;
      padding: 10px;
      width: 100%;
      height: 30%;
      overflow: scroll;
      & h1 {
        color: black;
        font-size: 17px;
        font-weight: 500;
        text-transform: capitalize;
      }

    }
  }
}
.p{
  margin-top: 10%;
  background: transparent;
  border: none;
  text-transform: capitalize;
  color: #2b91e7;
  opacity: 0;
  transition: 0.3s;
}
.news-title{
  display: flex;
  justify-content: center;
  & h1 {
    text-transform: capitalize;
    font-weight: 700;
    margin: 20px 0;
    font-size: 32px ;
  }
  & a {
    padding:7px 15px ;
    border: 1px solid #2b91e7;
    border-radius: 5px;
  }
}