

#footer{
  width: 100%;
  height: 50vh;
  position: relative;
  padding:30px 0 ;
  background: #24809D;
}
a, a:hover{
  text-decoration: none;
}


.footer{

  position: relative;
  display: flex;
  flex-direction: column;

  &__a{
    font-size: 18px;
    color: white;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
    &:before{
      position: absolute;
      content : "";
      width: 100%;
      height: 2px;
      bottom: -2%;
      transition: .4s;
      transform: scale(0);
    }
    &:hover:before{
      transform: scale(1);
    }
    &:hover .footer_block{
      transform: scaleX(1);
    }
    &:hover{
      color: #5fafce;
    }
    &:hover img{
      transform: rotate(90deg);
    }
  }
}
.footer_img{
  transition: .4s;
  width: 20px;
  height: 17px;
  margin-top: -1%;
}
.footer_block{
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  border-radius: 5px;
  background: white;
  width: 300px;
  transition: .3s;
  left: 0;
  z-index: 10;
  transform: scaleX(0);
  & a {
    font-size: 15px;
    font-weight: 250;
    color: black;
  }
}
.footer_logo{
  width: 80px;
  margin-left: 50px;
  position: relative;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover:before {
    opacity: 1;
  }
  &:before {
    transition: .3s;
    font-size: 12px;
    color: black;
    width: 120px;
    position: absolute;
    content : "Главная ст...";
    right: 20px;
    top: 0;
    opacity: 0;
  }
}

.footer_title{
  font-weight: 500;
  font-size: 28px;
  color: white;
}
.footer_icons {
  display: flex;
  align-items: center;
  margin: 10px 0;
  &_div{
    border-radius: 50%;
    padding: 5px;
    background: white;
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      object-fit: cover;
      width: 80%;
      height: 80%;
    }
  }
  h5{
    font-size: 16px;
    font-weight: 250;
    color: white;
    margin: 0 10px;
    & a{
      font-size: 16px;
      font-weight: 250;
      color: white;
    }
  }
  &_div2{
    & a{
      font-size: 16px;
      font-weight: 250;
      color: white;
    }
  }
}
.footer_gmails{
  display: flex;
  & a {
    margin: 20px 10px;
  }
}
.footer-img{
  width: 80%;
  position: relative;
  overflow: hidden;
  & img{
    width: 100%;
    height: 100%;object-fit: cover;
  }
}
@media (max-width: 576px) {
  .footer-img{
    display: none;
  }

  #footer{
    height: 80vh;
  }
}
@media (max-width: 414px) {
  #footer{
    height: 45vh;
  }
  .footer{
    display: none;
  }
}