

.nor-doc{
  padding:30px 20px ;
  background: rgb(240,240,240);
  &-title{
    display: flex;
    justify-content: center;
    & h1 {
      font-size: 32px;
      font-weight: 500;
    }
  }
  &-block{
    &-link {
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 250;
    }
  }
}